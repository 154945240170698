import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styles from "./modal.module.scss";

export default function Modal({
    children,
    closeIcon,
    width,
    active,
    backdropClosable,
    onClose,
    modalStyles,
    bodyStyles,
    header,
    modalClassName,
    wrapperClassName,
    pageBuilderVariables,
}: {
    children: ReactNode;
    closeIcon: ReactNode;
    width?: string | number;
    active: boolean;
    backdropClosable?: boolean;
    onClose: () => void;
    modalStyles?: React.CSSProperties | undefined;
    bodyStyles?: React.CSSProperties | undefined;
    header?: string | undefined;
    modalClassName?: string;
    wrapperClassName?: string;
    pageBuilderVariables?: React.CSSProperties | undefined;
}): ReactElement {
    const [mounted, setMounted] = useState(false);
    const [animation, setAnimation] = useState(false);

    const startAnimation = () => {
        setTimeout(() => {
            setAnimation(true);
        }, 100);
    };

    const resetAnimation = () => {
        setAnimation(false);
    };

    useEffect(() => {
        if (mounted) {
            if (active) {
                document
                    .getElementsByTagName("html")[0]
                    .classList.add("overflow-hidden");
                document.body.classList.add("overflow-hidden");
                if (window.innerWidth > 1024) {
                    document.body.style.width = "calc(100% - 15px)";
                }
                startAnimation();
            } else {
                document
                    .getElementsByTagName("html")[0]
                    .classList.remove("overflow-hidden");
                document.body.classList.remove("overflow-hidden");
                document.body.style.width = "";
                resetAnimation();
            }
        }
    }, [active]);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (mounted) {
        return createPortal(
            <div>
                <div
                    className={`${styles["wrapper"]} ${
                        active ? "flex" : "hidden"
                    } ${wrapperClassName ?? ""}`}
                    style={
                        pageBuilderVariables ? { ...pageBuilderVariables } : {}
                    }
                >
                    {active && (
                        <div
                            onClick={backdropClosable ? onClose : undefined}
                            className={styles["backdrop"]}
                        />
                    )}
                    <div
                        className={`${styles["modal"]} ${
                            wrapperClassName ? styles[wrapperClassName] : ""
                        } ${
                            modalClassName ?? ""
                        } duration-500 transition-all ease-in-out transform ${
                            animation
                                ? "-translate-y-0 opacity-100"
                                : "-translate-y-10 opacity-0"
                        }`}
                        style={{
                            width: width
                                ? typeof width === "string"
                                    ? `${width}px`
                                    : width
                                : "520px",
                            maxWidth: "calc(100vw - 32px)",
                            pointerEvents: "auto",

                            ...modalStyles,
                        }}
                    >
                        {header && (
                            <div className={styles["header"]}>
                                <h1>{header}</h1>
                            </div>
                        )}

                        <div
                            className={`${styles["body"]} `}
                            style={bodyStyles}
                        >
                            <button
                                onClick={onClose}
                                type="button"
                                aria-label="Close"
                                className={styles["close-button"]}
                            >
                                <span className="close-icon">{closeIcon}</span>
                            </button>
                            <div style={{ height: "100%" }}>{children}</div>
                        </div>
                    </div>
                </div>
            </div>,
            document.body,
        );
    }

    return <></>;
}
